import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SkewLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import {
  getStudentProgress,
  getVerified,
  validateToken,
} from "../services/Mock_api";
import Modal from "@mui/material/Modal";
import { SubHeading } from "./../styleSheets/Style";
import { MyButton } from "./../styleSheets/Style";
import Box from "@mui/material/Box";
import { discardMock } from "../services/Mock_api";
import { useDispatch, useSelector } from "react-redux";
import { addUserData } from "../store/slices/userDataSlice";
import {
  addStudentResponse,
  addMockData,
  setCurrentSectionIndex,
} from "../store/slices/mockDataSlice";

const MainUserAuth = () => {
  const navigate = useNavigate();
  const { email, otp, mockId, setId } = useParams(); // send DATA TO VERIFICATION API FOR
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("pageType"); //startMock|resume|viewAnalysis
  const attemptId = searchParams.get("attemptId");
  const [loader, setLoader] = useState(true);
  const [type, setType] = useState();
  const [openModal, setModal] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    textAlign: "",
    height: 400,
    bgcolor: "white",
    borderRadius: "10px ",
    boxShadow: 24,
    p: 0,
    m: 0,
  };
  const buttonStyle = {
    background: "linear-gradient(91.59deg, #FD4153 18.67%, #F77A5B 98.68%)",
    width: "138px",
    color: "#fff",
    borderRadius: "20px",
  };
  const { email: prevEmail, uid } = useSelector((state) => state.userData);
  const { attemptId: prevAttemptId, mockId: prevMockId } = useSelector(
    (state) => state.mockData
  );
console.log("type",type);

  useEffect(() => {
    //const isMobileOrTablet = window.matchMedia("(max-width:1000px)").matches;
    // if (isMobileOrTablet) {
    //   navigate("/mobileErrorPage");
    // } else {
    startVerification();
    //}
  }, []);

  //resume mock(if question data is present)

  const resumeMock = () => {
    const isMobileOrTablet = window.matchMedia("(max-width:1000px)").matches;
    if (isMobileOrTablet) {
      navigate("/mobileErrorPage");
    } else {
      if (type === "adaptive") {
        navigate(`/adaptiveMain`);
      } else {
        navigate(`/main`);
      }
    }

  };

  // Removing question data      !!!!!!!1(Need to review later)

  const eraseQuestionData = async () => {
    setModal(false);
    setLoader(true);
    try {
      const res = await discardMock(prevAttemptId, uid);
      if (res?.status == 200) {
        localStorage.clear();
        setLoader(false);
        showToastMessageForDiscard();
        setTimeout(() => {
          window.location.href = "https://www.iquanta.in/cat-mock-test";
        }, 2000);
      }
    } catch (err) {
      showToastMessage(err?.response?.data?.message);
      ////console.log(err);
    }
  };

  //function for token refresh
  const validateTokenFunc = async (uid) => {
    try {
      const response = await validateToken(uid);
      if (response.status == 200) {
        localStorage.setItem("auth_token", response?.data?.accessToken);
      }
    } catch (err) {
      showToastMessage(err?.response?.data?.message);
      console.log(err);
      setTimeout(() => {
        window.location.href = "https://www.iquanta.in/cat-mock-test";
      }, 2000);
    }
  };
  // Function for VERIFICATION
  const startVerification = async () => {
    window.localStorage.clear(); //clearing local storage on calling function
    ////console.log("verifying");
    try {
      const response = await getVerified(email, otp, mockId);
      //console.log("start verification", response);
      if (response?.status == 200) {
        localStorage.setItem("auth_token", response?.data?.data.accessToken);
        dispatch(addUserData(response?.data.data));
        const arr_length = response?.data?.data?.attemptList?.length;
        const isOnboarding = response?.data?.data?.isOnboarding;
        const allowNewAttempt = response?.data?.data?.allowNewAttempt;
        const mockType = response?.data?.data?.mockType;
        const isSectionReorder = response?.data?.data?.isSectionReorder;
        const sectionOrderOptions = response?.data?.data?.sectionOrderOptions;
        const isMobileOrTablet = window.matchMedia("(max-width:1000px)").matches;
        //console.log(arr_length);
        console.log(mockType);
        
        if (mockType === "adaptive") {
          setType("adaptive")
        }
        if (allowNewAttempt && arr_length == 0 || pageType == "startMock") {
          //USER GOING TO ATTEMPT MOCK


          if (isMobileOrTablet) {
            navigate("/mobileErrorPage");
          }



          else if (isOnboarding && !isMobileOrTablet) {
            navigate(`/onboarding`, {
              state: {
                mockId: mockId,
                setId: setId,
                mockType: mockType,
                isSectionReorder: isSectionReorder
              },
            });
          }
          else if (!isOnboarding && isSectionReorder) {
            navigate(`/template`, {
              state: {
                mockId: mockId,
                setId: setId,
                mockType: mockType,
                isSectionReorder: isSectionReorder,
                sectionOrderOptions: sectionOrderOptions
              },
            });
          }
          else if (!isOnboarding && !isMobileOrTablet) {
            navigate(`/instructions`, {
              state: {
                mockId: mockId,
                setId: setId,
              },
            });
          }
        } else {
          const Last_attempt_id_Obj =
            response.data.data.attemptList.length > 0 &&
            response?.data?.data.attemptList[response.data.data.attemptList.length - 1];
          const isSectionSubmitted = Last_attempt_id_Obj?.isSubmitted;
          if (pageType) {
            if (pageType == "viewAnalysis" && attemptId) {
              //setId contains the attempId in this case
              navigate(
                `/analysis/${mockId}/${attemptId}/overall`
              );
            }
            else if (pageType == "startMock" && isSectionSubmitted && allowNewAttempt === true) {

              if (isMobileOrTablet) {
                navigate("/mobileErrorPage");
              }

              if (isOnboarding && !isMobileOrTablet) {
                navigate(`/onboarding`, {
                  state: {
                    mockId: mockId,
                    setId: setId,
                  },
                });
              } else if (!isOnboarding && !isMobileOrTablet) {
                navigate(`/instructions`, {
                  state: {
                    mockId: mockId,
                    setId: setId,
                  },
                });
              }
            }
            else if (pageType == "resume" && attemptId) {
              //alert('171');

              if (isMobileOrTablet) {
                navigate("/mobileErrorPage");
              }
              else {
                const response = await getStudentProgress(
                  attemptId, uid
                );
                console.log("182:", response?.data?.data);
                if (response.status == 200 && Object.keys(response?.data?.data).length > 0) {
                  dispatch(addMockData(response?.data?.data));
                  dispatch(addStudentResponse(response?.data?.data.sections));
                  dispatch(
                    setCurrentSectionIndex(response?.data?.data.submitCount)
                  );
                  setModal(true);
                }
                else if (Object.keys(response?.data?.data).length == 0) {
                  showToastMessageForAnalysis();
                  setTimeout(() => {
                    navigate(
                      `/analysis/${mockId}/${Last_attempt_id_Obj.attemptId}/overall`
                    );
                  }, 2000);
                }
              }
            }
          }
          else {
            // USER RESUME MOCK OR REDIRECT TO ANALYSIS OR NEW ATTEMPT IF ALLOWED //OLD MOCK FLOW

            if (isSectionSubmitted && allowNewAttempt === false) {
              // GOING TO ANALYSIS
              showToastMessageForAnalysis();
              setTimeout(() => {
                navigate(
                  `/analysis/${mockId}/${Last_attempt_id_Obj.attemptId}/overall`
                );
              }, 2000);
            }
            else if (isSectionSubmitted && allowNewAttempt === true) {
              // new attempt mock start

              if (isMobileOrTablet) {
                navigate("/mobileErrorPage");
              }
              if (!isMobileOrTablet && isOnboarding) {
                navigate(`/onboarding`, {
                  state: {
                    mockId: mockId,
                    setId: setId,
                  },
                });
              } else if (!isOnboarding && !isMobileOrTablet) {
                navigate(`/instructions`, {
                  state: {
                    mockId: mockId,
                    setId: setId,
                  },
                });
              }
            }
            else if (isSectionSubmitted === false) {
              // RESUME MOCK FUNC

              if (isMobileOrTablet) {
                navigate("/mobileErrorPage");
              }
              else {
                const response = await getStudentProgress(Last_attempt_id_Obj.attemptId, uid);

                if (response.status == 200) {
                  dispatch(addMockData(response?.data?.data));
                  dispatch(addStudentResponse(response?.data?.data.sections));
                  dispatch(
                    setCurrentSectionIndex(response?.data?.data.submitCount)
                  );
                  setModal(true);
                }
              }
            }
          }
        }
      }
    } catch (err) {
      //console.log(err, "231");
      //alert('!!!!')
      showToastMessage(err?.response?.data?.message);
      ////console.log(err);
      // setTimeout(() => {
      //   localStorage.clear();
      //   window.location.href = "https://www.iquanta.in/cat-mock-test";
      // }, 2000);
    }
  };

  const showToastMessageForAnalysis = () => {
    toast.info(
      "You have already attempted this mock, redirecting to your Analysis",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    return null;
  };
  const showToastMessage = (msg) => {
    toast.error(msg == undefined ? "Some error occurred!" : msg, {
      position: toast.POSITION.TOP_CENTER,
    });

    return setLoader(false);
  };
  const showToastMessageForDiscard = () => {
    toast.info("Your Mock has been Discarded", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <ToastContainer autoClose={1500} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "95vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loader ? (
          <>
            <SkewLoader color="var(--orange)" size="85px" />
            <h6
              className="loader_title"
              style={{ textAlign: "center", marginTop: "1em" }}
            >
              Authenticating..... Please wait!
            </h6>
          </>
        ) : (
          <div></div>
        )}
      </div>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-center">
            <Box
              component="img"
              sx={{
                height: 200,
                width: 600,
              }}
              alt="no IMage"
              src="/ModalImage.png"
            />
          </div>
          <div className="d-flex justify-content-center p-4">
            <SubHeading
              style={{ color: "red", fontWeight: "700", fontSize: "16px" }}
            >
              You have not completed your previous mock, press continue to
              resume or press discard.
            </SubHeading>
          </div>
          <div className="d-flex justify-content-evenly">
            <MyButton
              variant="contained"
              sx={{
                bgcolor: "#EBEBEB",
                color: "black",
                borderRadius: "20px",
                ":hover": { background: "#EBEBEB", color: "black" },
                width: "138px",
              }}
              onClick={() => eraseQuestionData()}
            >
              Discard
            </MyButton>
            <MyButton
              variant="contained"
              style={buttonStyle}
              onClick={() => resumeMock()}
            >
              Continue
            </MyButton>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
export default MainUserAuth;
