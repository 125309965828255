import React, { useState, useEffect, useRef } from "react";
import { SubHeading, BootstrapButton, MyButton } from "../styleSheets/Style";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import { Typography, Stack, TextField, Box, ButtonBase ,Checkbox} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../styleSheets/centerMain.css";
import Calc from "../Components/Calculator";
import ContentDrawer from "../Components/ContentDrawer";
import QuestionPaper from "../Components/QuestionPaper";
import InstructionButton from "../Components/InstructionButton";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import ButtonSubmit from "../Components/SubmitButton";
import { fetchQuestions } from "../services/Mock_api";
import { PuffLoader } from "react-spinners";
import ImageButton from "../Components/ImageButton";
import { NewTimer } from "../Components/AdaptiveTimer";
import { useDispatch, useSelector } from "react-redux";
import { addMockData } from "../store/slices/mockDataSlice";
import { addStudentResponse, setCurrentSectionIndex } from "../store/slices/mockDataSlice";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import { saveStudentProgressNonToggle, saveStudentProgressToggle, submitAllSections ,submitSectionWise} from "../services/Mock_api";

function AdaptiveMockMain() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [selectedAnswer, setSelectedAnswer] = useState(null); //state store select options index
  const [selectedAnswerMulti,setSelectedAnswerMulti] = useState([]);
  const [inputVal, setInputVal] = useState(""); //if have iinput box data store in this state
  const [Data, setData] = useState([]); //Main mock data get state
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0); // set indexing for display the question
  const [isFullScreen, setFullScreen] = useState(false);
  const [questionStatus, setQuestionStatus] = useState(null);
  const { state } = useLocation();
  const COUNTER_KEY = "my-counter";
  const intervalRef = useRef(0);
  const [count, setCount] = useState(0);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Redux store access

  const dispatch = useDispatch();
  const {
    mockname,
    sections,
    isToggleAllowed,
    isWindowPeriod,
    isCalculatorAllowed,
    studentResponse,
    currentSectionIndex,
    commonTimer,
    attemptId,
    mockId,
  } = useSelector((state) => state.mockData);
  const { uid } = useDispatch((state) => state.userData);
  // fetching mock data from store
  useEffect(() => {
   // alert('66');
    if (studentResponse) {
      setQuestionStatus(studentResponse[currentSectionIndex].questions);
      console.log("section changed!!!!!!!!!!!!!!");
      setInputVal("");
      setSelectedAnswer(null);
      setSelectedQuestionIndex(0);
      prevQuestionIndex.current = null;
      if (window.localStorage.getItem(COUNTER_KEY) === null || window.localStorage.getItem(COUNTER_KEY) === undefined) {
        //alert('75');
        if (isToggleAllowed) {
          window.localStorage.setItem(COUNTER_KEY, commonTimer);
        } else {
         // alert('79');
          const currentSection = sections[currentSectionIndex];
          const { timer = 60 } = currentSection;
          console.log("80", timer);
          window.localStorage.setItem(COUNTER_KEY, timer);
        }
     }
      setLoading(false);
    }
  }, [currentSectionIndex]);

  // syncing question status with redux store
  useEffect(() => {
    if (questionStatus?.length > 0) {
      const tempObj = studentResponse[currentSectionIndex];
      console.log(tempObj);
      let arr = [...studentResponse];
      // const obj = {...tempObj, questions : questionStatus}
      arr.splice(currentSectionIndex, 1, {
        ...tempObj,
        questions: questionStatus,
      });
      // console.log(arr);
      dispatch(addStudentResponse(arr));
    }
  }, [questionStatus]);

  const showToastMessageForEmptyQuestion = () => {
    toast.info("Please mark the response", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // for storing previous value of question index
  const prevQuestionIndex = useRef(null);

  //Function for full screen :
  const handleFullScreen = () => {
    const element = document.documentElement;
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setFullScreen(false);
    } else if (element.requestFullscreen) {
      element.requestFullscreen();
      setFullScreen(true);
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
      setFullScreen(true);
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
      setFullScreen(true);
    }
  };
  // Function for getting a keyboard value from keyboard component

  const handleKeyPress = (key) => {
    setInputVal((prevInput) => prevInput + key);
    // const updatedData = [...Data];
    // updatedData[selectedQuestionIndex].selectedAnswer = inputVal + key;
    // setData(updatedData);
  };

  const handleBackspace = () => {
    setInputVal((prevInput) => prevInput.slice(0, -1));
    // const updatedData = [...Data];
    // updatedData[selectedQuestionIndex].selectedAnswer = inputVal.slice(0, -1);
    // setData(updatedData);
  };

  // console.log("Question status", questionStatus);
  // console.log("Student Response!!!", studentResponse);
  // console.log(currentSectionIndex, "currentSectionIndex");
  //console.log(sectionName, sectionTime);

  // Function for setting different stages(accrd to student input)
  // //console.log("data", Data);
  // //console.log("questionStatus", questionStatus);
  // Stage = 0 --> Not Visited
  // Stage = 1 --> Answered
  // Stage = 2 --> Not Answered
  // Stage = 3 --> Mark for review
  // Stage = 4 --> Answered & Mark for review
  const setStage = async(buttonType) => {
    const questionType = questionStatus[selectedQuestionIndex].questionId.questionType;
    console.log(buttonType);
    let studentAnswer;
    let studentAnswerIndex;
    let studentAnswerMulti=[];
    if (questionType === "scq") {
      studentAnswerIndex = selectedAnswer !== null ? selectedAnswer : null;
      studentAnswer =
        questionStatus[selectedQuestionIndex].questionId.options[studentAnswerIndex] !== undefined
          ? questionStatus[selectedQuestionIndex].questionId.options[studentAnswerIndex]
          : null;
    }
    if (questionType === "tita") {
      studentAnswer = inputVal;
    }

    if (questionType === "mcq") {
      console.log("170:",selectedAnswerMulti)
      studentAnswerMulti = selectedAnswerMulti;
     }
    

    const obj = questionStatus[selectedQuestionIndex];
    let newObj;
    //console.log("176:",studentAnswerMulti,studentAnswer)
    if(studentAnswer==""||studentAnswer==null){
     showToastMessageForEmptyQuestion();
    }
    else if ((studentAnswer !== null && studentAnswer !== "" && studentAnswerIndex !== null && buttonType === "save")||(studentAnswerMulti !== null && studentAnswerMulti.length>0 && buttonType === "save")) {
      
      newObj = {
        ...obj,
        stage: 1,
        studentAnswer,
        studentAnswerIndex,
        studentAnswerMulti,
        duration: count,
      };
     
      let arr = [...questionStatus];
      arr.splice(selectedQuestionIndex, 1, newObj);
      setQuestionStatus(arr);

      
      // if(selectedQuestionIndex+1==sections[currentSectionIndex].questions.length){
      //   alert('call section submit');

      // }
      //else
      return nextInd();
    } 
   
    else {
      
     // console.log("223");
      const newObj = {
        ...obj,
        stage: 4,
        studentAnswer,
        studentAnswerIndex,
        studentAnswerMulti,
        duration: count,
      };
      //console.log(newObj);
      let arr = [...questionStatus];
      arr.splice(selectedQuestionIndex, 1, newObj);
      setQuestionStatus(arr);
      //setSelectedAnswerMulti([]);
      return nextInd();
    }
    
  };

  // Function showing prev value(If any) on question render

  const showPreviousValue = () => {
    //console.log("currentQueIndex", selectedQuestionIndex);
    if (questionStatus?.length > 0) {
      if (questionStatus[selectedQuestionIndex].studentAnswer !== null) {
        if (questionStatus[selectedQuestionIndex].questionId.questionType === "tita") {
          setInputVal(questionStatus[selectedQuestionIndex].studentAnswer);
        }
        if (questionStatus[selectedQuestionIndex].questionId.questionType === "scq") {
          setSelectedAnswer(questionStatus[selectedQuestionIndex].studentAnswerIndex);
        }
        if (questionStatus[selectedQuestionIndex].questionId.questionType === "mcq") {
          setSelectedAnswer(questionStatus[selectedQuestionIndex].studentAnswerMulti);
        }
      } else if (questionStatus[selectedQuestionIndex].studentAnswer === null) {
        setSelectedAnswer(null);
        setInputVal("");
        //setSelectedAnswerMulti([]);
      } else {
        setSelectedAnswer(null);
        setInputVal("");
        // setSelectedAnswerMulti([]);
      }
    }
  };

  const handleSectionSubmit=async()=>{
   // alert("change next index 260");
    await setStage('save');
    window.localStorage.removeItem(COUNTER_KEY);
    //being called from inside timer
  //   const { questions, section } = studentResponse[currentSectionIndex];
  //   const studentResponseArr = questions.map((e) => {
  //     return {
  //       questionId: e.questionId._id,
  //       studentAnswer: e?.studentAnswer,
  //       studentAnswerMulti:e?.studentAnswerMulti,
  //       studentAnswerIndex: e?.studentAnswerIndex === undefined || null ? null : e?.studentAnswerIndex,
  //       duration: e?.duration,
  //       stage: e?.stage,
  //     };
  //   });
  //   console.log(105, studentResponseArr);
  //   console.log(questions, "questions");
  //   const remainingTime = Number(window.localStorage.getItem(COUNTER_KEY));
  // const sectionResponse = await submitSectionWise(mockId, attemptId, section, studentResponseArr, uid, remainingTime);
  //  console.log("279",sectionResponse);
  //    if (sectionResponse.status == 200) {
  //     window.localStorage.removeItem(COUNTER_KEY);
  //     if (currentSectionIndex < studentResponse.length - 1) {
  //       //setIsLoaded(false);
  //       alert('284');
  //       dispatch(setCurrentSectionIndex(currentSectionIndex + 1));
  //     } else {
  //       //setIsLoaded(true);
  //       //setState(2);
  //     }
  //   }
     
  }

  useEffect(() => {
    showPreviousValue();
    if (questionStatus) {
      if (questionStatus[selectedQuestionIndex]?.duration) {
        setCount(Number(questionStatus[selectedQuestionIndex]?.duration));
      } else {
        // alert('inside else');
        setCount(0);
      }
    } else {
      setCount(0);
    }
  }, [ selectedQuestionIndex, questionStatus]);

  // Function setting stage "Not Answered" on just changing selectedQuestionIndex
  useEffect(() => {
    const settingStage2 = () => {
      if (questionStatus?.length > 0) {
        //console.log("prevQueIndex", prevQuestionIndex.current);
        const preQuestionIndex = prevQuestionIndex.current;
        const obj = questionStatus[preQuestionIndex];
        if (obj?.stage === 0) {
          const newObj = {
            ...obj,
            stage: 2,
            duration: null,
          };
          //console.log(newObj);
          let arr = [...questionStatus];
          arr.splice(preQuestionIndex, 1, newObj);
          setQuestionStatus(arr);
        }
      }
    };
    settingStage2();
  }, [selectedQuestionIndex]);

  
  // clear Response
  const clearResponse = () => {
    setSelectedAnswer(null);
    setInputVal("");
  };

  useEffect(() => {
  

    const interval = setInterval(() => {
      setCount((count) => count + 1);
    }, 1000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  // console.log(count);
  // button for next func
  const nextInd = () => {
    if (selectedQuestionIndex === questionStatus.length - 1) {
      // alert("You can not go to next section!!!");
      return false;
    }
    setSelectedQuestionIndex(selectedQuestionIndex + 1);
    setSelectedAnswer(null);
    setInputVal("");
    //setSelectedAnswerMulti([]);
  };

  //Restricting User from text selection and Copying

  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault();
    };
    const disableTextSelection = (event) => {
      if (window.getSelection) {
        const selection = window.getSelection();
        if (selection.toString().length !== 0) {
          event.preventDefault();
        }
      }
    };
    const disableKeys = (event) => {
      const disabledKeys = ["KeyA", "KeyC", "KeyX", "KeyF", "KeyS", "KeyD", "KeyI"];
      // Array of keys to disable

      if (disabledKeys.includes(event.code)) {
        event.preventDefault();
      }
    };
    document.addEventListener("contextmenu", disableRightClick);
    // document.addEventListener("selectstart", disableTextSelection);
    document.addEventListener("keydown", disableKeys);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      // document.removeEventListener("selectstart", disableTextSelection);
      document.addEventListener("keydown", disableKeys);
    };
  });

  const handleBeforeUnloadEvent = async (event) => {
    if (!confirmedNavigation) {
      event.preventDefault();
      event.returnValue = ""; // For Chrome
      const remainingTime = Number(window.localStorage.getItem(COUNTER_KEY));
      //added condition for aagman series
      if (isWindowPeriod == true) {
        const studentResponseArr = studentResponse.map((section) => {
          let answers = section.questions.map((obj) => {
            return {
              questionId: obj.questionId._id,
              studentAnswer: obj?.studentAnswer,
              studentAnswerMulti:obj?.studentAnswerMulti,
              studentAnswerIndex: obj.studentAnswerIndex,
              duration: obj?.duration,
              stage: obj?.stage,
            };
          });
          return {
            section: section.section,
            questions: answers,
          };
        });
        console.log("391:",studentResponseArr);
        const response = await submitAllSections(mockId, attemptId, studentResponseArr, uid);
      } else {
        if (isToggleAllowed) {
          const studentResponseArr = studentResponse.map((section) => {
            const answers = section.questions.map((obj) => {
              return {
                questionId: obj.questionId._id,
                studentAnswer: obj?.studentAnswer,
                studentAnswerIndex: obj.studentAnswerIndex,
                studentAnswerMulti:obj.studentAnswerMulti,
                duration: obj?.duration,
                stage: obj?.stage,
              };
            });
            return {
              section: section.section,
              questions: answers,
            };
          });
          console.log(studentResponseArr);
          await saveStudentProgressToggle(mockId, attemptId, studentResponseArr, uid, remainingTime);
        } else {
          const { questions, section } = studentResponse[currentSectionIndex];
          const studentResponseArr = questions.map((e) => {
            return {
              questionId: e.questionId._id,
              studentAnswer: e?.studentAnswer,
              studentAnswerMulti:e?.studentAnswerMulti,
              studentAnswerIndex: e?.studentAnswerIndex === undefined || null ? null : e?.studentAnswerIndex,
              duration: e?.duration,
              stage: e?.stage,
            };
          });
          console.log(105, studentResponseArr);
          console.log(questions, "questions");

          await saveStudentProgressNonToggle(mockId, attemptId, section, studentResponseArr, uid, remainingTime);
          //localStorage.clear();
        }
      }
    }
  };

  const handleMultiAnswerSelect=(value)=>{
    console.log(selectedAnswerMulti,435);
    if(selectedAnswerMulti.length>0)
    {
    let index = selectedAnswerMulti.indexOf(value);
    if(index===-1){
      setSelectedAnswerMulti([...selectedAnswerMulti,value])
    }
    else{
      let updatedArr = selectedAnswerMulti.filter(item=>item!==value)
      setSelectedAnswerMulti(updatedArr);
    }
  }
    else
    {
      setSelectedAnswerMulti([value])
    }
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnloadEvent);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnloadEvent);
    };
  }, [studentResponse]);

  useEffect(() => {
    window.history.pushState(null, document.title, location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, location.href);
    });
    return () => {
      window.removeEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, location.href);
      });
    };
  }, []);

  return loading ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "80vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PuffLoader color="#202021" loading size={70} speedMultiplier={1} />
    </div>
  ) : (
    <div className="container-fluid bg-white">
      <div className="row p-3 pe-1" style={{ height: "100%", userSelect: "none" }}>
        {/* Left main container */}
        <ToastContainer autoClose={1500} />
        <div className="col-12 " style={{ height: "100%" }}>
          <div className="row ">
            <div className="container">
              <SubHeading sx={{ color: "black", textAlign: "start", pl: 1 }}>Section</SubHeading>
              <div className="d-flex justify-content-between align-items-baseline py-1">
                <Stack spacing={2} direction="row">
                  {/* Only one button show in section part */}
                  

                  
                      <Button
                        key={currentSectionIndex}
                        variant="contained"
                        disabled={false}
                        sx={{
                          borderRadius: 3.5,
                          backgroundColor: "blue",
                          boxShadow: 6,
                          fontWeight: 600,
                        }}
                      >
                        {sections[currentSectionIndex].name?.toUpperCase()}
                      </Button>
                    
                  
                </Stack>

                <div style={{ display: "flex", flexDirection: "row" }}>

                   
                  <span>
                    <Tooltip title={isFullScreen ? "Exit full screen" : "Full screen"}>
                      <img
                        src={isFullScreen ? "/Group28.jpg" : require("../images/Open vector.png")}
                        width="70"
                        className="img-fluid p-2"
                        onClick={handleFullScreen}
                        alt="arrow-icon"
                        role="button"
                      />
                    </Tooltip>
                  </span>
                  {isCalculatorAllowed ? (
                    <span role="button">
                      <Tooltip title="Calculator">
                        <Calc />
                      </Tooltip>
                    </span>
                  ) : (
                    <></>
                  )}

                  <div
                    className="timer fw-bold"
                    style={{
                      color: "#37833e",
                      borderRadius: "18px",
                      height: "50px",
                      width: "100px",
                      textAlign: "center",
                      paddingTop: "3px",
                      marginTop: "6px",
                      marginLeft: "8px",
                    }}
                  >
                    <>
                      <div style={{ color: "black", fontSize: "14px" }}>Questions</div>
                      {selectedQuestionIndex + 1}/{sections[currentSectionIndex].questions.length}
                    </>
                  </div> 
                    
                 

                  <div
                    className="timer fw-bold"
                    style={{
                      color: "#FF0103",
                      borderRadius: "18px",
                      height: "50px",
                      width: "100px",
                      textAlign: "center",
                      paddingTop: "3px",
                      marginTop: "6px",
                      marginLeft: "8px",
                    }}
                  >
                    <>
                      <div style={{ color: "black", fontSize: "14px" }}>Time Left</div>
                      <NewTimer
                        // mockId={state.mockId}
                        // defaultMinutes={localStorage.getItem(COUNTER_KEY)}
                        //defaultMinutes={5}
                        sectionIndex={currentSectionIndex}
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div
            className="row px-1 py-3  mt-2"
            style={{
              background: "var(--light-background)",
              borderRadius: "30px",
              height: "80vh",
            }}
          >
            {/* left side content div */}
            <div
              className={
                questionStatus?.length > 0 && questionStatus[selectedQuestionIndex]?.questionId.isPara === true
                  ? "col-7 "
                  : "d-none"
              }
            >
              <div className="p-2" style={{ borderRadius: "30px", background: "white" , height:"100%" }}>
                <div className="leftContent">
                  {
                    <ContentDrawer
                      context={
                        questionStatus?.length > 0 && questionStatus[selectedQuestionIndex]?.questionId.isPara === true
                          ? questionStatus[selectedQuestionIndex]?.questionId.context
                          : "No paragraph"
                      }
                      image={
                        questionStatus?.length > 0 && // Check if Data array has at least one element
                        questionStatus[selectedQuestionIndex]?.questionId.image
                          ? questionStatus[selectedQuestionIndex]?.questionId.image.map((item) => {
                              return <ImageButton src={item} />;
                            })
                          : null
                      }
                    />
                  }
                </div>
              </div>
            </div>
            {/*  right side question  div */}
            <div
              className={
                questionStatus?.length > 0 && questionStatus[selectedQuestionIndex]?.questionId.isPara === true
                  ? "col-5 text-justify"
                  : "col-12  text-justify"
              }
              style={{height:"90%"}}
            >
              <div className="p-2" style={{ borderRadius: "30px", background: "white",height:"100%" }}>
                <div className="p-5 rightContent overflow-auto" style={{height:"100%" }} >
                  <Typography variant="paragraph">
                    Question : {selectedQuestionIndex + 1}
                    <br />
                    {questionStatus?.length > 0 && (
                      <div>
                        <Latex>{questionStatus[selectedQuestionIndex]?.questionId.question || ""}</Latex>
                      </div>
                    )}
                  </Typography>
                  {/* <div className="img-wrapper">
                  <img style={{ cursor: "pointer" }} src={questionStatus[selectedQuestionIndex]?.image} className="img-fluid hover-zoom" />
                </div> */}
                  <br />
                  {questionStatus?.length > 0 && (
                    <div className="text-start">
                      {questionStatus[selectedQuestionIndex]?.questionId.questionType === "tita" ||
                      questionStatus[selectedQuestionIndex]?.questionId.questionType === null ? (
                        <>
                          <TextField
                            id="outlined-basic"
                            label="Enter Answer"
                            variant="outlined"
                            value={inputVal !== "" ? inputVal : ""}
                            // onChange={(e) => setInputVal(e.target.value)}
                            // inputRef={(input) => input && input.focus()}
                            sx={{
                              my: 3,
                              color: "black",
                              width: "400px",
                              "& label.Mui-focused": {
                                color: "black",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "var(--orange)",
                              },
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "var(--orange)",
                                },
                                "&:hover fieldset": {
                                  borderColor: "var(--orange)",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "var(--orange)",
                                },
                              },
                            }}
                            autoComplete="off"
                          />
                          <div className="keys p-3 rounded shadow">
                            <div className="d-flex flex-wrap justify-content-center gap-2 fs-4 m-2 ">
                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "auto",
                                  p: 1,
                                  borderRadius: "30px",
                                }}
                                variant="contained"
                                onClick={() => handleKeyPress("1")}
                              >
                                1
                              </BootstrapButton>
                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "auto",
                                  p: 1,
                                  borderRadius: "30px",
                                }}
                                variant="contained"
                                onClick={() => handleKeyPress("2")}
                              >
                                2
                              </BootstrapButton>
                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "auto",
                                  p: 1,
                                  borderRadius: "25px",
                                }}
                                variant="contained"
                                onClick={() => handleKeyPress("3")}
                              >
                                3
                              </BootstrapButton>
                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "auto",
                                  p: 1,
                                  borderRadius: "25px",
                                }}
                                variant="contained"
                                onClick={() => handleKeyPress("4")}
                              >
                                4
                              </BootstrapButton>
                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "auto",
                                  p: 1,
                                  borderRadius: "25px",
                                }}
                                variant="contained"
                                onClick={() => handleKeyPress("5")}
                              >
                                5
                              </BootstrapButton>
                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "auto",
                                  p: 1,
                                  borderRadius: "25px",
                                }}
                                variant="contained"
                                onClick={() => handleKeyPress("6")}
                              >
                                6
                              </BootstrapButton>
                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "auto",
                                  p: 1,
                                  borderRadius: "25px",
                                }}
                                variant="contained"
                                onClick={() => handleKeyPress("7")}
                              >
                                7
                              </BootstrapButton>
                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "auto",
                                  p: 1,
                                  borderRadius: "25px",
                                }}
                                variant="contained"
                                onClick={() => handleKeyPress("8")}
                              >
                                8
                              </BootstrapButton>
                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "auto",
                                  p: 1,
                                  borderRadius: "25px",
                                }}
                                variant="contained"
                                onClick={() => handleKeyPress("9")}
                              >
                                9
                              </BootstrapButton>
                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "auto",
                                  p: 1,
                                  borderRadius: "25px",
                                }}
                                variant="contained"
                                onClick={() => handleKeyPress("0")}
                              >
                                0
                              </BootstrapButton>
                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "auto",
                                  p: 1,
                                  borderRadius: "25px",
                                }}
                                variant="contained"
                                onClick={() => handleKeyPress(".")}
                              >
                                .
                              </BootstrapButton>
                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "auto",
                                  p: 1,
                                  borderRadius: "25px",
                                }}
                                variant="contained"
                                onClick={() => handleKeyPress("-")}
                              >
                                -
                              </BootstrapButton>

                              <BootstrapButton
                                className="flex-item"
                                sx={{
                                  width: "151px",
                                  p: 1,
                                  borderRadius: "25px",
                                }}
                                variant="contained"
                                onClick={() => handleBackspace()}
                              >
                                Backspace
                              </BootstrapButton>
                            </div>
                          </div>
                        </>
                      ) : 
                      questionStatus[selectedQuestionIndex]?.questionId.questionType === "scq"?
                      (
                        <FormControl sx={{ width: "100%" }} key={selectedQuestionIndex}>
                          {/* Answer Select Section */}
                          <RadioGroup
                            sx={{ width: "100%" }}
                            aria-labelledby="demo-radio-buttons-group-label"
                            name={`answer_${selectedQuestionIndex}`}
                            value={selectedAnswer !== undefined ? selectedAnswer : null}
                            onChange={(e) => {
                              const value = e.target.value;
                              setSelectedAnswer(parseInt(value));
                            }}
                          >
                            {questionStatus[selectedQuestionIndex]?.questionId.options != null &&
                              questionStatus[selectedQuestionIndex]?.questionId.options.map((option, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={index}
                                  control={<Radio />}
                                  sx={{ marginTop: 2 }}
                                  label={
                                    <div>
                                      <small>
                                        <Latex>{option || ""}</Latex>
                                      </small>
                                    </div>
                                  }
                                />
                              ))}
                          </RadioGroup>
                        </FormControl>
                      ):
                      questionStatus[selectedQuestionIndex]?.questionId.questionType =="mcq"?(
                       questionStatus[selectedQuestionIndex]?.questionId.options != null &&
                       questionStatus[selectedQuestionIndex]?.questionId.options.map((option, index) => (
<FormControl sx={{ width: "100%" }} component="fieldset" variant="standard">
 {/* <FormControlLabel component="legend">"Test Multi Select"</FormControlLabel>  */}
<FormGroup
onChange={(e) => {
  const value = e.target.value;
  handleMultiAnswerSelect(value);
  // setSelectedAnswerMulti([...selectedAnswerMulti,value]);
}}
>
  <FormControlLabel
    control={
      <Checkbox checked={                                  
                selectedAnswerMulti?.includes(option)||
                questionStatus[selectedQuestionIndex].studentAnswerMulti?.includes(option)
                ? true
                : false
              } />
    }
    key={index}
    value={option}
    sx={{ marginTop: 2 }}
    label={
       <div>
         <small>
            <Latex>{option || ""}</Latex>
         </small>
      </div>}
    />
                            
 </FormGroup>

</FormControl>))
                        
                      ):<></>}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Bottom button div */}
            <div className="d-flex justify-content-between py-2 align-items-end ">
              <div>
                {/* <MyButton variant="contained" height="41" onClick={() => setStage("review")}>
                  Mark for Review & Next
                </MyButton> */}
                <MyButton
                  variant="contained"
                  height="41"
                  onClick={() => {
                    clearResponse();
                  }}
                >
                  Clear Response
                </MyButton>
              </div>

              <div className="">
                {(sections[currentSectionIndex].questions.length==selectedQuestionIndex+1)?
                <BootstrapButton
                  variant="contained "
                  height="41"
                  onClick={() => handleSectionSubmit()}
                  sx={{ fontSize: "13px", color: "white" }}
                  disabled={false}
                >
                  Submit Section
                </BootstrapButton>:
                <BootstrapButton
                  variant="contained "
                  height="41"
                  onClick={() => setStage("save")}
                  sx={{ fontSize: "13px", color: "white" }}
                  disabled={false}
                >
                  Save & Next
                </BootstrapButton>}
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
}

export default AdaptiveMockMain;
